<template>
    <div class="row my-2 jobs-button align-items-center">
        <div class="col-3 text-primary fw-boldest d-flex justify-content-center align-items-center">
            {{ roomNumber }}
        </div>
        <div class="col-2 col-sm-3 d-flex justify-content-center align-items-center">
            <i class="unit-status-icon rounded-circle" :class="status"></i>
        </div>
        <div class="col-3 time small fst-italic text-center">
            {{ statusTime }}
        </div>
        <div class="col-4 col-sm-3 d-flex flex-wrap align-items-center justify-content-center text-center">
            <div class="unit-check d-flex flex-wrap" v-for="unitCheck in unitChecks" :key="unitCheck.id">
                <div class="mx-1 d-flex flex-column">
                    <i :class="unitCheckIconsByType[unitCheck.unit_check_type_id]"/>
                    <div class="font-small-4 fw-bold">
                        {{ unitCheckAbbreviatedDescriptionsByTypeId[unitCheck.unit_check_type_id] }}
                    </div>
                </div>
            </div>
            <div class="unit-check d-flex flex-wrap" v-if="requiresMeterEntry">
                <div class="mx-1 d-flex flex-column">
                    <i class="fa fa-tachometer-alt"/>
                    <div class="font-small-4 fw-bold">METER</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import JobData from "@/http/data/job-data";
import ContractData from "@/http/data/contract-data";
import UnitCheckData from "@/http/data/unit-check-data";
import {buildingContext, unitCheckContext, unitContext} from "@/store";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import BuildingData from "@/http/data/building-data";
import ContractMeterData from "@/http/data/contract-meter-data";

import _ from "lodash";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

class Props {
    activeContract = prop({
        required: false,
        type: Object,
        default: null
    });
    contracts = prop({
        required: false,
        type: Array,
        default: []
    });
    jobs = prop({
        required: true,
        type: Array
    });
    meters = prop({
        required: true,
        type: Array
    });
    status = prop({
        required: true,
        type: String
    });
    unitChecks = prop({
        required: true,
        type: Array
    });
    unitId = prop({
        required: true,
        type: String
    });
}

@Options({
    name: "UnitChecksButton"
})
export default class UnitChecksButton extends Vue.with(Props) {
    declare activeContract: ContractData|null;
    declare contracts: ContractData[];
    declare jobs: JobData[];
    declare meters: ContractMeterData[];
    declare status: string;
    declare unitChecks: UnitCheckData[];
    declare unitId: string;

    parseRoomNumber = unitContext.getters.parseRoomNumber;

    get requiresMeterEntry(): boolean {
        return !!_.find(this.meters, meter => meter.getNextRequiredDate());
    }

    get roomNumber(): string {
        return this.parseRoomNumber(this.unitId);
    }

    get selectedBuilding(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get statusTime(): string {
        return unitContext.getters.statusTime(this.activeContract, this.status, this.selectedBuilding);
    }

    get unitCheckAbbreviatedDescriptionsByTypeId(): UnitCheckGetters["unitCheckAbbreviatedDescriptionsByTypeId"] {
        return unitCheckContext.getters.unitCheckAbbreviatedDescriptionsByTypeId;
    }

    get unitCheckIconsByType(): UnitCheckState["unitCheckIconsByType"] {
        return unitCheckContext.state.unitCheckIconsByType;
    }
}
</script>

<style lang="scss" scoped>
.jobs-button {
    &:hover {
        cursor: pointer;
        filter: brightness(.75);
    }
}

.time {
    font-size: .8em;
}

.unit-status-icon {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
}
</style>