
import _ from "lodash";
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {buildingContext, contractContext, jobContext, meterContext, unitCheckContext, unitContext} from "@/store";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import UnitChecksButton from "@/components/controls/UnitChecksButton.vue";
import UnitCheckData from "@/http/data/unit-check-data";
import UnitButton from "@/components/controls/UnitButton.vue";
import UnitData from "@/http/data/unit-data";
import ContractGetters from "@/store/modules/contract/contract-getters";
import JobGetters from "@/store/modules/job/job-getters";
import UnitGetters from "@/store/modules/unit/unit-getters";
import UnitActions from "@/store/modules/unit/unit-actions";
import BuildingData from "@/http/data/building-data";
import ContractMeterData from "@/http/data/contract-meter-data";

@Options({
    name: "Units",
    components: {
        UnitButton,
        DefaultLayout,
        UnitChecksButton
    },
})
export default class Units extends Vue {
    selectUnit!: UnitActions["selectUnit"];

    drawerIsVisible = false;

    get showTodayJobsOnly(): boolean {
        return jobContext.state.showTodayJobsOnly;
    }

    set showTodayJobsOnly(value: boolean) {
        jobContext.mutations.setShowTodayJobsOnly(value);
    }

    beforeMount(): void {
        this.selectUnit = unitContext.actions.selectUnit;
    }

    get activeContractByUnitId(): ContractGetters["activeContractByUnitId"] {
        return contractContext.getters.activeContractByUnitId;
    }

    get contractsByUnitId(): ContractGetters["contractsByUnitId"] {
        return contractContext.getters.contractsByUnitId;
    }

    get jobsByUnitId(): JobGetters["jobsByUnitId"] {
        return jobContext.getters.jobsByUnitId;
    }

    get selectedBuilding(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get units(): UnitData[] {
        if (!this.selectedBuilding.id) return [];

        return unitContext.getters.unitsByBuildingId[this.selectedBuilding.id];
    }

    get unitIdsWithUnfinishedTodos(): string[] {
        return _.chain(this.units)
            .filter(u =>
                !!this.unfinishedUnitChecksForSelectedBuildingByUnit[u.id as string] ||
                !!this.unfinishedMetersForSelectedBuildingByUnitId[u.id as string])
            .map(u => u.id as string)
            .value();
    }

    get unfinishedMetersForSelectedBuildingByUnitId(): Record<string, ContractMeterData[]> {
        return _.chain(meterContext.state.contractMeters)
            .filter(meter => meter.building_id === this.selectedBuilding.id && !!meter.getNextRequiredDate())
            .groupBy(meter => meter.unit_id)
            .value();
    }

    get unfinishedUnitChecksForSelectedBuildingByUnit(): _.Dictionary<UnitCheckData[]> {
        return _.chain(unitCheckContext.state.unitChecks)
            .filter((unitCheck: UnitCheckData) =>
                unitCheck.building_id === this.selectedBuilding?.id &&
                unitCheck.checked_at === null)
            .orderBy(["unit_id"])
            .groupBy((unitCheck: UnitCheckData) => unitCheck.unit_id)
            .value();
    }

    get unitStatusByUnitId(): UnitGetters["unitStatusByUnitId"] {
        return unitContext.getters.unitStatusByUnitId;
    }

    async onSelectUnit(unitId: string): Promise<void> {
        await this.selectUnit(unitId);
        await this.$router.push({name: "Unit"});
    }
}
