
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import JobData from "@/http/data/job-data";
import ContractData from "@/http/data/contract-data";
import UnitCheckData from "@/http/data/unit-check-data";
import {buildingContext, unitCheckContext, unitContext} from "@/store";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import BuildingData from "@/http/data/building-data";
import ContractMeterData from "@/http/data/contract-meter-data";

import _ from "lodash";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

class Props {
    activeContract = prop({
        required: false,
        type: Object,
        default: null
    });
    contracts = prop({
        required: false,
        type: Array,
        default: []
    });
    jobs = prop({
        required: true,
        type: Array
    });
    meters = prop({
        required: true,
        type: Array
    });
    status = prop({
        required: true,
        type: String
    });
    unitChecks = prop({
        required: true,
        type: Array
    });
    unitId = prop({
        required: true,
        type: String
    });
}

@Options({
    name: "UnitChecksButton"
})
export default class UnitChecksButton extends Vue.with(Props) {
    declare activeContract: ContractData|null;
    declare contracts: ContractData[];
    declare jobs: JobData[];
    declare meters: ContractMeterData[];
    declare status: string;
    declare unitChecks: UnitCheckData[];
    declare unitId: string;

    parseRoomNumber = unitContext.getters.parseRoomNumber;

    get requiresMeterEntry(): boolean {
        return !!_.find(this.meters, meter => meter.getNextRequiredDate());
    }

    get roomNumber(): string {
        return this.parseRoomNumber(this.unitId);
    }

    get selectedBuilding(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get statusTime(): string {
        return unitContext.getters.statusTime(this.activeContract, this.status, this.selectedBuilding);
    }

    get unitCheckAbbreviatedDescriptionsByTypeId(): UnitCheckGetters["unitCheckAbbreviatedDescriptionsByTypeId"] {
        return unitCheckContext.getters.unitCheckAbbreviatedDescriptionsByTypeId;
    }

    get unitCheckIconsByType(): UnitCheckState["unitCheckIconsByType"] {
        return unitCheckContext.state.unitCheckIconsByType;
    }
}
