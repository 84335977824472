<template>
    <default-layout>
        <h1 class="text-center my-4">{{ selectedBuilding.name }}</h1>
        <h2 class="mx-2 my-3 fw-bold text-center">Unfinished todos</h2>
        <div class="d-flex justify-content-center">
            <div class="mx-2 w-100">
                <div class="unit-check-item"
                     v-for="unitId in unitIdsWithUnfinishedTodos" :key="unitId">
                    <unit-checks-button :active-contract="activeContractByUnitId[unitId] ?? null"
                                        :contracts="contractsByUnitId[unitId] ?? []"
                                        :jobs="jobsByUnitId[unitId] ?? []"
                                        :meters="unfinishedMetersForSelectedBuildingByUnitId[unitId] ?? []"
                                        :status="unitStatusByUnitId[unitId]"
                                        :unitChecks="unfinishedUnitChecksForSelectedBuildingByUnit[unitId] ?? []"
                                        :unitId="unitId"
                                        @click="onSelectUnit(unitId)"/>
                </div>
            </div>
        </div>
        <h2 class="mx-2 mt-3 mb-0 fw-bold">All Units</h2>
        <div class="my-3 d-flex justify-content-center">
            <el-switch
                style="display: block"
                v-model="showTodayJobsOnly"
                active-color="#2196f3"
                inactive-color="#2196f3"
                active-text="Today only"
                inactive-text="All jobs"
            />
        </div>
        <div class="d-inline-flex gap-1 flex-wrap p-2 justify-content-center">
            <unit-button v-for="unit in units"
                         :key="unit.id"
                         :unit="unit"
                         :jobs="jobsByUnitId[unit.id] ?? []"
                         :status="unitStatusByUnitId[unit.id]"
                         @click="onSelectUnit(unit.id)"/>
        </div>
    </default-layout>
    <button class="w-100 btn btn-info text-white position-sticky fixed-bottom py-0"
            @click="drawerIsVisible = true">
        <span class="font-small-2">VIEW LEGEND</span>
        <i class="mx-2 fa fa-question-circle font-small-1"/>
    </button>
    <el-drawer
        :size="260"
        title="Legend"
        v-model="drawerIsVisible"
        direction="btt">
        <div>
            <div class="mx-3 mb-2 text-secondary d-flex align-items-center">
                <i class="me-2 d-inline-block rounded-circle unit-status-icon status-arriving"/>
                <span>Arriving</span>
            </div>
            <div class="mx-3 mb-2 text-secondary d-flex align-items-center">
                <i class="me-2 d-inline-block rounded-circle unit-status-icon status-blocked"/>
                <span>Blocked</span>
            </div>
            <div class="mx-3 mb-2 text-secondary d-flex align-items-center">
                <i class="me-2 d-inline-block rounded-circle unit-status-icon status-departing"/>
                <span>Departing</span>
            </div>
            <div class="mx-3 mb-2 text-secondary d-flex align-items-center">
                <i class="me-2 d-inline-block rounded-circle unit-status-icon status-occupied"/>
                <span>Occupied</span>
            </div>
            <div class="mx-3 mb-2 text-secondary d-flex align-items-center">
                <i class="me-2 d-inline-block rounded-circle unit-status-icon status-vacant"/>
                <span>Vacant</span>
            </div>
        </div>
    </el-drawer>
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {buildingContext, contractContext, jobContext, meterContext, unitCheckContext, unitContext} from "@/store";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import UnitChecksButton from "@/components/controls/UnitChecksButton.vue";
import UnitCheckData from "@/http/data/unit-check-data";
import UnitButton from "@/components/controls/UnitButton.vue";
import UnitData from "@/http/data/unit-data";
import ContractGetters from "@/store/modules/contract/contract-getters";
import JobGetters from "@/store/modules/job/job-getters";
import UnitGetters from "@/store/modules/unit/unit-getters";
import UnitActions from "@/store/modules/unit/unit-actions";
import BuildingData from "@/http/data/building-data";
import ContractMeterData from "@/http/data/contract-meter-data";

@Options({
    name: "Units",
    components: {
        UnitButton,
        DefaultLayout,
        UnitChecksButton
    },
})
export default class Units extends Vue {
    selectUnit!: UnitActions["selectUnit"];

    drawerIsVisible = false;

    get showTodayJobsOnly(): boolean {
        return jobContext.state.showTodayJobsOnly;
    }

    set showTodayJobsOnly(value: boolean) {
        jobContext.mutations.setShowTodayJobsOnly(value);
    }

    beforeMount(): void {
        this.selectUnit = unitContext.actions.selectUnit;
    }

    get activeContractByUnitId(): ContractGetters["activeContractByUnitId"] {
        return contractContext.getters.activeContractByUnitId;
    }

    get contractsByUnitId(): ContractGetters["contractsByUnitId"] {
        return contractContext.getters.contractsByUnitId;
    }

    get jobsByUnitId(): JobGetters["jobsByUnitId"] {
        return jobContext.getters.jobsByUnitId;
    }

    get selectedBuilding(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get units(): UnitData[] {
        if (!this.selectedBuilding.id) return [];

        return unitContext.getters.unitsByBuildingId[this.selectedBuilding.id];
    }

    get unitIdsWithUnfinishedTodos(): string[] {
        return _.chain(this.units)
            .filter(u =>
                !!this.unfinishedUnitChecksForSelectedBuildingByUnit[u.id as string] ||
                !!this.unfinishedMetersForSelectedBuildingByUnitId[u.id as string])
            .map(u => u.id as string)
            .value();
    }

    get unfinishedMetersForSelectedBuildingByUnitId(): Record<string, ContractMeterData[]> {
        return _.chain(meterContext.state.contractMeters)
            .filter(meter => meter.building_id === this.selectedBuilding.id && !!meter.getNextRequiredDate())
            .groupBy(meter => meter.unit_id)
            .value();
    }

    get unfinishedUnitChecksForSelectedBuildingByUnit(): _.Dictionary<UnitCheckData[]> {
        return _.chain(unitCheckContext.state.unitChecks)
            .filter((unitCheck: UnitCheckData) =>
                unitCheck.building_id === this.selectedBuilding?.id &&
                unitCheck.checked_at === null)
            .orderBy(["unit_id"])
            .groupBy((unitCheck: UnitCheckData) => unitCheck.unit_id)
            .value();
    }

    get unitStatusByUnitId(): UnitGetters["unitStatusByUnitId"] {
        return unitContext.getters.unitStatusByUnitId;
    }

    async onSelectUnit(unitId: string): Promise<void> {
        await this.selectUnit(unitId);
        await this.$router.push({name: "Unit"});
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.unit-check-item {
    &:first-child {
        border-top: $secondary solid 1px;
    }

    &:last-child {
        border-bottom: $secondary solid 1px;
    }

    &:not(:last-child) {
        border-bottom: $secondary dashed 1px;
    }
}

.unit-status-icon {
    width: 1rem;
    height: 1rem;
}
</style>