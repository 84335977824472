<template>
    <div class="unit-btn mb-2">
        <div class="text-center fw-boldest my-1">{{ roomNumber }}</div>
        <div class="d-flex justify-content-center align-items-center gap-2">
            <i class="unit-status-icon rounded-circle" :class="status" />
            <i class="unit-work-icon fa fa-list" v-if="hasWork" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import UnitData from "@/http/data/unit-data";
import {buildingContext, jobContext, unitContext, userContext} from "@/store";
import JobData from "@/http/data/job-data";
import _ from "lodash";
import dayjs from "dayjs";
import BuildingData from "@/http/data/building-data";

class Props {
    jobs = prop({
        required: true,
        type: Array
    });
    status = prop({
        required: true,
        type: String
    })
    unit = prop({
        required: true,
        type: Object
    });
}

@Options({
    name: "JobButton"
})
export default class UnitButton extends Vue.with(Props) {
    declare jobs: JobData[];
    declare status: string;
    declare unit: UnitData;

    parseRoomNumber = unitContext.getters.parseRoomNumber;

    get hasWork(): boolean {
        return _.filter(this.jobs, (job: JobData) => {
            return job.cancelled_at === null
                && job.performed_at === null 
                && (
                    (jobContext.state.showTodayJobsOnly && dayjs().isSame(job.perform_date, "day")) ||
                    (!jobContext.state.showTodayJobsOnly && dayjs().isSameOrAfter(job.perform_date))
                )
                && (
                    job.type === "concierge"
                    || (userContext.state.session && userContext.state.session.roles.includes(job.type))
                )
                && job.shouldIndex()
        }).length > 0;
    }
    
    get isVacant(): boolean {
        return this.unitStatus === "status-vacant";
    }

    get roomNumber(): string {
        return this.unit.id ? this.parseRoomNumber(this.unit.id) : "missing_room_id";
    }

    get selectedBuilding(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get unitStatus(): string {
        return this.unit.id ? unitContext.getters.unitStatusByUnitId[this.unit.id] : "missing_room_id";
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.unit-btn {
    border: solid 1px $secondary;
    width: 65px;
    height: 65px;

    &:hover {
        cursor: pointer;
        filter: brightness(.75);
    }
}

.unit-status-icon {
    width: 1rem;
    height: 1rem;
}

.unit-work-icon {
    font-size: 1.2em;
}
</style>